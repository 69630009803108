import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', loadChildren: () => import('./modules/Hogar/Hogar.module').then(m => m.HogarPageModule) },
  { path: 'Hogar', loadChildren: () => import('./modules/Hogar/Hogar.module').then(m => m.HogarPageModule) },
  { path: 'Empresa', loadChildren: () => import('./modules/empresa/empresa.module').then(m => m.EmpresaModule) },
  { path: 'Pyme', loadChildren: () => import('./modules/Pyme/Pyme.module').then(m => m.PymeModule) },
  { path: 'instalar', loadChildren: () => import('./modules/instalar-fibexplay/instalar-fibexplay.module').then(m => m.InstalarFibexplayModule) },
  { path: 'roku', loadChildren: () => import('./modules/roku-fibexplay/roku-fibexplay.module').then(m => m.RokuFibexplayModule) },
  { path: 'fibexplay', loadChildren: () => import('./modules/fibexplay/fibexplay.module').then(m => m.FibexPlayModule) },
  { path: 'Zona-cobertura', loadChildren: () => import('./modules/coverage-area/coverage-area.module').then(m => m.CoverageAreaModule) },
  { path: 'soporte', loadChildren: () => import('./modules/app-support/app-support.module').then(m => m.AppSupportModule) },
  { path: 'politica-de-cookies', loadChildren: () => import('./modules/cookie-privacy/cookie-privacy.module').then(m => m.CookiePrivacyModule) },
  { path: 'club-fibex', loadChildren: () => import('./modules/club-fibex/club-fibex.module').then(m => m.ClubFibexModule) },
  { path: 'promo-clubfibex', loadChildren: () => import('./modules/club-fibex/components/promo-clubfibex/promo-clubfibex.module').then(m => m.PromoClubfibexModule) },
  // { path: ':source', loadChildren: () => import('./modules/generic-forms/generic-forms.module').then(m => m.GenericFormsModule)},
  { path: 'fullturbo', loadChildren: () => import('./modules/fullturbo/fullturbo.module').then(m => m.FullturboModule)},
  { path: 'conecta-empresa', loadChildren: () => import('./modules/conecta-tu-empresa/conecta-tu-empresa.module').then(m => m.ConectaTuEmpresaModule)},
  { path: ':client', loadChildren: () => import('./modules/Notitarde/notitarde.module').then(m => m.NotitardeModule)},
  // { path: ':client', loadChildren: () => import('./modules/form-contact-general/form-contact-general.module').then(m => m.FormContactGeneralModule) },
  // { path: 'Labottega', loadChildren: () => import('./form-contact-general/form-contact-general.module').then(m => m.FormContactGeneralModule) },
  // { path: 'la-bottega', loadChildren: () => import('./form-contact-general/form-contact-general.module').then(m => m.FormContactGeneralModule) },
  // { path: 'la-zona', loadChildren: () => import('./form-contact-general/form-contact-general.module').then(m => m.FormContactGeneralModule) },
  // { path: 'contacto', loadChildren: () => import('./form-contact-general/form-contact-general.module').then(m => m.FormContactGeneralModule) },
  // { path: 'links', loadChildren: () => import('./form-contact-general/form-contact-general.module').then(m => m.FormContactGeneralModule) },
  //{ path: '', loadChildren: './landing-page4/landing-page4.module#LandingPage4Module' },
  // { path: '**', component: PageNotFoundComponent }
  //{ path: '', component: RootComponent },
  //{ path: 'landing-page4', loadChildren: () => import('./landing-page4/landing-page4.module').then(m => m.LandingPage4Module) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
